import { useState, useEffect } from "react";
import Loader from "../shared/loader";
import Api from '../api/api';
import { Link } from 'react-router-dom';

export default function Sites(props) {

    const [clients, setClients] = useState(null);
    const [sites, setSites] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getClients = function () {
        Api.getClients(
            (data) => setClients(data),
            () => setClients(null),
            props.organisationId,
            props.token
        );
    };

    const getSites = function () {
        Api.getSites(
            (data) => {

                data.sort((a, b) => {
                    if (a.clientName.toUpperCase() < b.clientName.toUpperCase()) {
                        return -1;
                    } else if (a.clientName.toUpperCase() > b.clientName.toUpperCase()) {
                        return 1;
                    }

                    if (a.name.toUpperCase() < b.name.toUpperCase()) {
                        return -1;
                    } else if (a.name.toUpperCase() > b.name.toUpperCase()) {
                        return 1;
                    }

                    return 0;
                });

                setSites(data);
                setIsLoading(false);
            },
            () => {
                setSites(null);
                setIsLoading(false);
            },
            props.organisationId,
            props.token
        );
    };

    useEffect(() => {
        getClients();
        getSites();
    }, []);

    return <>
        {isLoading && <Loader />}
        {!isLoading &&
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-1 d-flex align-items-center pr-0">
                                        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Sites</h5>
                                    </div>
                                </div>
                            </div>
                            <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                <thead className="bg-200 text-900">
                                    <tr>
                                        <th>Client</th>
                                        <th>Site</th>
                                        <th>Site Code</th>
                                        <th className="text-center">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sites && sites !== null && sites.map((site) => {
                                        var url = `/organisation/${props.organisationId}/client/${site.clientId}/site/${site.id}`;
                                        return (
                                            <tr>
                                                <td>{site.clientName}</td>
                                                <td><Link to={url}><h6 className="mb-0">{site.name}</h6></Link></td>
                                                <td>{site.siteCode}</td>
                                                <td className="text-center">{site.isActive && <><i className="fas fa-check text-success"></i></>}{!site.isActive && <><i className="fas fa-times text-danger"></i></>}</td>

                                            </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>;
}