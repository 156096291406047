//const baseUrl = "http://localhost:59982"
const baseUrl = process.env.REACT_APP_APT_API_URL;

export default {
    handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    },

    get(url, onSuccess, onError, token) {
        fetch(baseUrl + url,
            { headers: { 'Authorization': 'Bearer ' + token } }
        )
            .then(this.handleErrors)
            .then(response => response.json())
            .then(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            }).catch((error) => {
                if (onError) {
                    onError();
                }
            });
    },

    delete(url, onSuccess, onError, token) {
        fetch(baseUrl + url, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token
            },
            redirect: 'follow'
        })
            .then(this.handleErrors)
            .then((data) => {
                if (onSuccess) {
                    onSuccess(data);
                }
            }).catch((error) => {
                if (onError) {
                    onError();
                }
            });
    },

    post(url, body, onSuccess, onError, token) {
        fetch(baseUrl + url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token
            },
            body: body,
            redirect: 'follow'
        })
            .then(response => {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else {
                    return response.text();
                }
            })
            .then(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            }).catch((error) => {
                if (onError) {
                    onError(error);
                }
            });
    },

    postForBlob(url, body, onSuccess, onError, token) {

        let filename;

        fetch(baseUrl + url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token
            },
            body: body,
            responseType: 'blob'
        })
            .then(function (response) {
                
                let contentDisposition = response.headers.get("Content-Disposition");

                let contentDispositionParts = contentDisposition.split(";");
                let filenameElement = contentDispositionParts.find(x => x.indexOf("filename=") > 0);

                if (filenameElement) {
                    filename = filenameElement.split("=")[1];
                }
                
                return response.blob();
            }).then(function (blob) {
                
                if (onSuccess) {
                    onSuccess(blob, filename);
                }
            }).catch((error) => {
                if (onError) {
                    onError(error);
                }
            });
    },

    put(url, body, onSuccess, onError, token) {
        fetch(baseUrl + url, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token
            },
            body: body,
            redirect: 'follow'
        })
            .then(response => response.json())
            .then(data => {

                if (data?.isError) {
                    if (onError) {
                        onError(data);
                    }
                }
                else if (onSuccess) {
                    onSuccess(data);
                }
            }).catch((error) => {
                if (onError) {
                    onError(error);
                }
            });
    },

    getUser(onSuccess, onError, token) {
        this.get('/user', onSuccess, onError, token);
    },

    removeUser(onSuccess, onError, userId, orgId, token) {
        this.delete('/users/' + userId + '/organisations/' + orgId, onSuccess, onError, token);
    },

    getEnums(onSuccess, onError, token) {
        this.get('/enums', onSuccess, onError, token);
    },

    getUsers(onSuccess, onError, pageNumber, orgId, token) {
        let body = JSON.stringify({
            "organisationId": orgId,
            "pageNumber": pageNumber
        });
        this.post('/users/search', body, onSuccess, onError, token);
    },

    editUser(onSuccess, onError, first, last, userId, token) {

        let body = JSON.stringify({
            "firstName": first,
            "lastName": last
        });

        this.put('/users/' + userId, body, onSuccess, onError, token);
    },

    editUserRole(onSuccess, onError, first, last, userId, role, clientId, siteIds, orgId, token) {

        let body = JSON.stringify({
            "firstName": first,
            "lastName": last,
            "organisationId": orgId,
            "role": role,
            "clientId": clientId,
            "siteIds": siteIds
        });

        this.put('/users/' + userId, body, onSuccess, onError, token);
    },

    getNumberRanges(onSuccess, onError, orgId, token) {
        this.get('/' + orgId + '/reference-number-ranges', onSuccess, onError, token);
    },

    getNumberRange(onSuccess, onError, rangeId, orgId, token) {
        this.get('/' + orgId + '/reference-number-ranges/' + rangeId, onSuccess, onError, token);
    },

    addNewNumberRange(onSuccess, onError, prefix, start, minDigits, isActive, type, orgId, token) {

        let body = JSON.stringify({
            "contraventionType": type,
            "prefix": prefix,
            "start": start,
            "minimumDisplayDigits": minDigits,
            "isActive": isActive,
        });

        this.put('/' + orgId + '/reference-number-ranges/add', body, onSuccess, onError, token);
    },

    editNumberRange(onSuccess, onError, prefix, start, minDigits, isActive, type, rangeId, orgId, token) {

        let body = JSON.stringify({
            "contraventionType": type,
            "prefix": prefix,
            "start": start,
            "minimumDisplayDigits": minDigits,
            "isActive": isActive,
        });

        this.put('/' + orgId + '/reference-number-ranges/' + rangeId, body, onSuccess, onError, token);
    },

    getOrganisations(onSuccess, onError, token) {
        this.get('/organisations', onSuccess, onError, token);
    },

    getOrganisation(onSuccess, onError, orgId, token) {
        this.get('/organisations/' + orgId, onSuccess, onError, token);
    },

    addOrganisation(onSuccess, onError, organisationName, debtCollectionMethod, status, shardName, addressLine1, addressLine2, addressLine3, postCode, townOrCity, emailAddress, mobileNumber, contactName, phoneNumber, token) {
        let body = JSON.stringify({
            "name": organisationName,
            "isActive": status,
            "shardName": shardName,
            "addressLine1": addressLine1,
            "addressLine2": addressLine2,
            "addressLine3": addressLine3,
            "postCode": postCode,
            "townOrCity": townOrCity,
            "contactEmailAddress": emailAddress,
            "contactMobileNumber": mobileNumber,
            "contactPhoneNumber": phoneNumber,
            "contactName": contactName
        });

        this.put('/organisations/add', body, onSuccess, onError, token);
    },

    editOrganisation(onSuccess, onError, organisationName, debtCollectionMethod, status, addressLine1, addressLine2, addressLine3, postCode, townOrCity, emailAddress, mobileNumber, contactName, phoneNumber, orgId, token) {

        let body = JSON.stringify({
            "name": organisationName,
            "isActive": status,
            "addressLine1": addressLine1,
            "addressLine2": addressLine2,
            "addressLine3": addressLine3,
            "postCode": postCode,
            "townOrCity": townOrCity,
            "contactEmailAddress": emailAddress,
            "contactMobileNumber": mobileNumber,
            "contactPhoneNumber": phoneNumber,
            "contactName": contactName
        });

        this.put('/organisations/' + orgId, body, onSuccess, onError, token);
    },

    getClients(onSuccess, onError, orgId, token) {
        this.get('/' + orgId + '/clients', onSuccess, onError, token);
    },

    addClient(onSuccess, onError, name, type, companyAddress, postCode, townOrCity, county, country, status, companyEmailAddress, companyPhoneNumber, emailAddress, mobileNumber, contactFirstName, contactLastName, jobTitle, phoneNumber, parkfolioUsername, parkfolioPassword, isPortalEnabled, orgId, token) {

        let body = JSON.stringify({
            "name": name,
            "clientType": type,
            "addressLine1": companyAddress,
            "postCode": postCode,
            "townOrCity": townOrCity,
            "county": county,
            "country": country,
            "isActive": status,
            "companyEmailAddress": companyEmailAddress,
            "companyPhoneNumber": companyPhoneNumber,
            "primaryContactEmail": emailAddress,
            "primaryContactMobile": mobileNumber,
            "primaryContactFirstName": contactFirstName,
            "primaryContactSurname": contactLastName,
            "primaryContactJobTitle": jobTitle,
            "primaryContactPhoneNumber": phoneNumber,
            "parkfolioUsername": parkfolioUsername,
            "parkfolioPassword": parkfolioPassword,
            "isPortalEnabled": isPortalEnabled,
        });

        this.put('/' + orgId + "/clients/add", body, onSuccess, onError, token);
    },

    getSingleClient(onSuccess, onError, orgId, clientId, token) {
        this.get('/' + orgId + '/clients/' + clientId, onSuccess, onError, token);
    },

    deleteClient(onSuccess, orgId, clientId, token) {
        this.delete('/' + orgId + '/clients/' + clientId, onSuccess, null, token);
    },

    editClient(onSuccess, onError, name, type, companyAddress, postCode, townOrCity, county, country, status, companyEmailAddress, companyPhoneNumber, emailAddress, mobileNumber, contactFirstName, contactLastName, jobTitle, phoneNumber, parkfolioUsername, parkfolioPassword, isPortalEnabled, orgId, clientId, token) {

        let body = JSON.stringify({
            "name": name,
            "clientType": type,
            "addressLine1": companyAddress,
            "postCode": postCode,
            "townOrCity": townOrCity,
            "county": county,
            "country": country,
            "isActive": status,
            "companyEmailAddress": companyEmailAddress,
            "companyPhoneNumber": companyPhoneNumber,
            "primaryContactEmail": emailAddress,
            "primaryContactMobile": mobileNumber,
            "primaryContactFirstName": contactFirstName,
            "primaryContactSurname": contactLastName,
            "primaryContactJobTitle": jobTitle,
            "primaryContactPhoneNumber": phoneNumber,
            "parkfolioUsername": parkfolioUsername,
            "parkfolioPassword": parkfolioPassword,
            "isPortalEnabled": isPortalEnabled,
        });

        this.put('/' + orgId + "/clients/" + clientId, body, onSuccess, onError, token);
    },

    getClientNotes(onSuccess, onError, orgId, clientId, token) {
        this.get('/' + orgId + '/clients/' + clientId + '/notes', onSuccess, onError, token);
    },

    addClientNote(onSuccess, onError, note, orgId, clientId, token) {

        let body = JSON.stringify({
            "note": note,
        });

        this.put('/' + orgId + "/clients/" + clientId + '/notes/add', body, onSuccess, onError, token);
    },

    getBreaches(onSuccess, onError, searchQuery, siteId, userId, isAssigned, fromDate, toDate, contraventionType, breachStatuses, pageNumber, orgId, token, signal) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);
        var raw = JSON.stringify({
            "searchQuery": searchQuery,
            "isAssigned": isAssigned,
            "siteId": siteId,
            "assignedToUserId": userId,
            "fromDate": fromDate,
            "toDate": toDate,
            "contraventionType": contraventionType,
            "breachStatuses": breachStatuses,
            "pageNumber": pageNumber
        });

        if (_dashSearchAbortController) {
            _dashSearchAbortController.abort()
        };

        _dashSearchAbortController = new AbortController()

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        if (signal !== null) {
            requestOptions.signal = signal
        } else {
            requestOptions.signal = _dashSearchAbortController.signal;
        }

        fetch(baseUrl + '/' + orgId + "/breaches/search", requestOptions)
            .then(response => response.json())
            .then(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            }).catch((error) => {
                if (onError && error.name !== "AbortError") {
                    onError(error);
                }
            });
    },

    getBreach(onSuccess, onError, breachId, orgId, token) {
        this.get('/' + orgId + '/breaches/' + breachId, onSuccess, onError, token);
    },

    getBreachPayments(onSuccess, onError, breachId, orgId, token) {
        this.get('/' + orgId + '/breaches/' + breachId + '/payments', onSuccess, onError, token);
    },

    addBreachPayment(onSuccess, onError, breachId, amount, reference, note, orgId, token) {
        this.put(`/${orgId}/breaches/${breachId}/payments/add`, JSON.stringify({
            amount,
            reference,
            note
        }), onSuccess, onError, token);
    },

    addWardenBreach(onSuccess, onError, siteId, vrm, vehicleColour, vehicleMake, vehicleModel, contraventionId, attachmentIds, startDate, endDate, orgId, token) {

        let body = JSON.stringify({
            contraventionId,
            siteId,
            vrm,
            vehicleMake,
            vehicleModel,
            vehicleColour,
            attachmentIds,
            startDate,
            endDate
        });

        this.put('/' + orgId + "/breaches/add", body, onSuccess, onError, token);
    },

    addBreachAttachment(onSuccess, attachmentId, breachId, orgId, token) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(baseUrl + '/' + orgId + "/breaches/" + breachId + '/attachments/' + attachmentId, requestOptions)
            .then(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            }).catch(error => console.log('error', error));
    },

    getBreachAttachments(onSuccess, onError, orgId, breachId, token) {
        this.get('/' + orgId + '/breaches/' + breachId + '/attachments', onSuccess, onError, token);
    },

    setBreachAttachmentIsLetterImage(onSuccess, onError, orgId, breachId, attachmentId, isLetterImage, token) {
        this.put(`/${orgId}/breaches/${breachId}/attachments/${attachmentId}/set-letter-image`, JSON.stringify({
            isLetterImage
        }), onSuccess, onError, token);
    },

    setReadyForLetters(onSuccess, onError, note, breachId, orgId, token) {

        let body = JSON.stringify({
            "note": note,
        });

        this.post('/' + orgId + "/breaches/" + breachId + '/ready-to-send-letters', body, onSuccess, onError, token);
    },

    getSites(onSuccess, onError, orgId, token) {
        this.get('/' + orgId + '/sites', onSuccess, onError, token);
    },

    getClientSites(onSuccess, onError, clientId, orgId, token) {
        this.get('/' + orgId + '/clients/' + clientId + '/sites', onSuccess, onError, token);
    },

    getSite(onSuccess, onError, siteId, orgId, token) {
        this.get('/' + orgId + '/sites/' + siteId, onSuccess, onError, token);
    },

    getAllSitesSummaries(onSuccess, onError, query, from, to, contraventionType, breachStatuses, orgId, token) {
        const body = JSON.stringify({
            "query": query,
            "from": from,
            "to": to,
            "contraventionType": contraventionType,
            "breachStatuses": breachStatuses,
        })
        this.post('/' + orgId + '/breaches/search/summary', body, onSuccess, onError, token);
    },

    addSite(onSuccess, onError, name, siteCode, iasIpcCode, status, addressLine1, addressLine2, addressLine3, postCode, townOrCity, iasEnabled, bpaEnabled, bpaCode, pofaEnabled, anprEnabled, maxAllowedParkingDuration, considerationPeriod, allowListEntryGrace, allowListExitGrace, contraventionsForOverStay, contraventionsForShortReturn,
        parkingSessionLimit, noReturnWithin, variableAnprEnabled, anprStart, anprEnd, variableAnprMaxAllowedParkingDuration, variableAnprConsiderationPeriod, variableAnprAllowListEntryGrace, variableAnprAllowListExitGrace, variableAnprContraventionsForOverStay,
        variableAnprContraventionsForShortReturn, variableAnprParkingSessionLimit, variableAnprNoReturnWithin, contractExpiry, payAndDisplaySpaces, preAllocatedSpaces, disabledSpaces, permitSpaces, otherSpaces, permitPrefix, parkonomySiteCode, ringGoZone, contraventionsForOverStayPaymentPeriodId,
        contraventionsForFailToPayWithinPeriodId, contraventionsForFailToPayId, isPayDisplay, orgId, clientId, token) {

        let body = JSON.stringify({
            "name": name,
            "siteCode": siteCode,
            "iasEnabled": iasEnabled,
            "iasCode": iasIpcCode,
            "bpaEnabled": bpaEnabled,
            "bpaCode": bpaCode,
            "pofaEnabled": pofaEnabled,
            "permitPrefix": permitPrefix,
            "parkonomySiteCode": parkonomySiteCode,
            "ringGoZone": ringGoZone,
            "addressLine1": addressLine1,
            "addressLine2": addressLine2,
            "addressLine3": addressLine3,
            "townOrCity": townOrCity,
            "postCode": postCode,
            "status": status,
            "clientId": clientId,
            "contractExpiry": contractExpiry,
            "isActive": status,
            "isAnprEnabled": anprEnabled,
            "maxAllowedParkingDurationMinutes": maxAllowedParkingDuration,
            "considerationPeriodMinutes": considerationPeriod,
            "allowListEntryGraceMinutes": allowListEntryGrace,
            "allowListExitGraceMinutes": allowListExitGrace,
            "noReturnWithinMinutes": noReturnWithin,
            "enforcementLimitMinutes": parkingSessionLimit,
            "overstayContraventionId": contraventionsForOverStay,
            "shortReturnContraventionId": contraventionsForShortReturn,
            "isVariableAnprEnabled": variableAnprEnabled,
            "variableAnprStart": anprStart,
            "variableAnprEnd": anprEnd,
            "variableAnprMaxAllowedParkingDurationMinutes": variableAnprMaxAllowedParkingDuration,
            "variableAnprConsiderationPeriodMinutes": variableAnprConsiderationPeriod,
            "variableAnprAllowListEntryGraceMinutes": variableAnprAllowListEntryGrace,
            "variableAnprAllowListExitGraceMinutes": variableAnprAllowListExitGrace,
            "variableAnprNoReturnWithinMinutes": variableAnprNoReturnWithin,
            "variableAnprEnforcementLimitMinutes": variableAnprParkingSessionLimit,
            "variableAnprOverstayContraventionId": variableAnprContraventionsForOverStay,
            "variableAnprShortReturnContraventionId": variableAnprContraventionsForShortReturn,
            "payAndDisplaySpaces": payAndDisplaySpaces,
            "preAllocatedSpaces": preAllocatedSpaces,
            "disabledSpaces": disabledSpaces,
            "permitSpaces": permitSpaces,
            "otherSpaces": otherSpaces,
            "failedToMakePaymentContraventionId": contraventionsForFailToPayId,
            "failedToMakePaymentWithinConsiderationPeriodContraventionId": contraventionsForFailToPayWithinPeriodId,
            "overstayedPaymentPeriodContraventionId": contraventionsForOverStayPaymentPeriodId,
            "isPayAndDisplayEnabled": isPayDisplay,
        });

        this.put('/' + orgId + "/sites/add", body, onSuccess, onError, token);
    },

    editSite(onSuccess, onError, name, siteCode, iasIpcCode, status, addressLine1, addressLine2, addressLine3, postCode, townOrCity, iasEnabled, bpaEnabled, bpaCode, pofaEnabled, anprEnabled, maxAllowedParkingDuration, considerationPeriod, allowListEntryGrace, allowListExitGrace, contraventionsForOverStay, contraventionsForShortReturn,
        parkingSessionLimit, noReturnWithin, variableAnprEnabled, anprStart, anprEnd, variableAnprMaxAllowedParkingDuration, variableAnprConsiderationPeriod, variableAnprAllowListEntryGrace, variableAnprAllowListExitGrace, variableAnprContraventionsForOverStay,
        variableAnprContraventionsForShortReturn, variableAnprParkingSessionLimit, variableAnprNoReturnWithin, contractExpiry, payAndDisplaySpaces, preAllocatedSpaces, disabledSpaces, permitSpaces, otherSpaces, permitPrefix, parkonomySiteCode, ringGoZone, contraventionsForOverStayPaymentPeriodId,
        contraventionsForFailToPayWithinPeriodId, contraventionsForFailToPayId, isPayDisplay, orgId, siteId, clientId, token) {

        var contractExpirySubmit = null;
        if (contractExpiry !== "") {
            contractExpirySubmit = contractExpiry;
        }

        let body = JSON.stringify({
            "name": name,
            "clientId": clientId,
            "siteCode": siteCode,
            "permitPrefix": permitPrefix,
            "parkonomySiteCode": parkonomySiteCode,
            "ringGoZone": ringGoZone,
            "iasEnabled": iasEnabled,
            "contractExpiry": contractExpirySubmit,
            "iasCode": iasIpcCode,
            "bpaEnabled": bpaEnabled,
            "bpaCode": bpaCode,
            "pofaEnabled": pofaEnabled,
            "addressLine1": addressLine1,
            "addressLine2": addressLine2,
            "addressLine3": addressLine3,
            "townOrCity": townOrCity,
            "postCode": postCode,
            "isActive": status,
            "isAnprEnabled": anprEnabled,
            "maxAllowedParkingDurationMinutes": maxAllowedParkingDuration,
            "considerationPeriodMinutes": considerationPeriod,
            "allowListEntryGraceMinutes": allowListEntryGrace,
            "allowListExitGraceMinutes": allowListExitGrace,
            "noReturnWithinMinutes": noReturnWithin,
            "enforcementLimitMinutes": parkingSessionLimit,
            "overstayContraventionId": contraventionsForOverStay,
            "shortReturnContraventionId": contraventionsForShortReturn,
            "isVariableAnprEnabled": variableAnprEnabled,
            "variableAnprStart": anprStart,
            "variableAnprEnd": anprEnd,
            "variableAnprMaxAllowedParkingDurationMinutes": variableAnprMaxAllowedParkingDuration,
            "variableAnprConsiderationPeriodMinutes": variableAnprConsiderationPeriod,
            "variableAnprAllowListEntryGraceMinutes": variableAnprAllowListEntryGrace,
            "variableAnprAllowListExitGraceMinutes": variableAnprAllowListExitGrace,
            "variableAnprNoReturnWithinMinutes": variableAnprNoReturnWithin,
            "variableAnprEnforcementLimitMinutes": variableAnprParkingSessionLimit,
            "variableAnprOverstayContraventionId": variableAnprContraventionsForOverStay,
            "variableAnprShortReturnContraventionId": variableAnprContraventionsForShortReturn,
            "payAndDisplaySpaces": payAndDisplaySpaces,
            "preAllocatedSpaces": preAllocatedSpaces,
            "disabledSpaces": disabledSpaces,
            "permitSpaces": permitSpaces,
            "otherSpaces": otherSpaces,
            "failedToMakePaymentContraventionId": contraventionsForFailToPayId,
            "failedToMakePaymentWithinConsiderationPeriodContraventionId": contraventionsForFailToPayWithinPeriodId,
            "overstayedPaymentPeriodContraventionId": contraventionsForOverStayPaymentPeriodId,
            "isPayAndDisplayEnabled": isPayDisplay,
        });

        this.put('/' + orgId + "/sites/" + siteId, body, onSuccess, onError, token);
    },

    deleteSite(onSuccess, orgId, siteId, token) {
        this.delete('/' + orgId + '/sites/' + siteId, onSuccess, null, token);
    },

    getSiteNotes(onSuccess, onError, orgId, siteId, token) {
        this.get('/' + orgId + '/sites/' + siteId + '/notes', onSuccess, onError, token);
    },

    addSiteNote(onSuccess, onError, note, orgId, siteId, token) {

        let body = JSON.stringify({
            "note": note,
        });

        this.put('/' + orgId + "/sites/" + siteId + '/notes/add', body, onSuccess, onError, token);
    },


    getSiteAllowList(onSuccess, onError, orgId, siteId, token) {
        this.get('/' + orgId + '/sites/' + siteId + '/allowed-vrms', onSuccess, onError, token);
    },

    getSiteAttachments(onSuccess, onError, orgId, siteId, token) {
        this.get('/' + orgId + '/sites/' + siteId + '/attachments', onSuccess, onError, token);
    },

    addSiteAttachment(onSuccess, onError, fileId, orgId, siteId, token) {
        this.put('/' + orgId + "/sites/" + siteId + '/attachments/' + fileId, null, onSuccess, onError, token);
    },

    getSiteContraventions(onSuccess, onError, orgId, siteId, token) {
        this.get('/' + orgId + '/sites/' + siteId + '/contraventions', onSuccess, onError, token);
    },

    addSiteContravention(onSuccess, onError, contraventionId, reducedChargeAmount, reducedChargeDays, fullChargeAmount, fullChargeDays, status, organisationId, siteId, token) {

        let body = JSON.stringify({
            "reducedChargeAmount": reducedChargeAmount,
            "reducedChargeDays": reducedChargeDays,
            "fullChargeAmount": fullChargeAmount,
            "fullChargeDays": fullChargeDays,
            "isActive": status,
        });

        this.put('/' + organisationId + "/sites/" + siteId + '/contraventions/' + contraventionId, body, onSuccess, onError, token);
    },

    getAllowList(onSuccess, onError, orgId, token) {
        this.get('/' + orgId + '/allowed-vrms', onSuccess, onError, token);
    },

    getAllowedVrm(onSuccess, onError, orgId, vrmId, token) {
        this.get('/' + orgId + '/allowed-vrms/' + vrmId, onSuccess, onError, token);
    },

    addAllowedVrm(onSuccess, onError, vrm, vehicleMake, vehicleModel, siteName, driverName, driverMobileNumber, startDate, endDate, reason, siteId, anprCameraId, roi, orgId, token) {

        let body = JSON.stringify({
            "vrm": vrm,
            "vehicleMake": vehicleMake,
            "vehicleModel": vehicleModel,
            "siteName": siteName,
            "driverName": driverName,
            "driverMobileNumber": driverMobileNumber,
            "startDate": startDate,
            "endDate": endDate,
            "siteId": siteId,
            anprCameraId,
            roi,
            "notes": reason
        });

        this.put('/' + orgId + "/allowed-vrms/add", body, onSuccess, onError, token);
    },

    editAllowedVrm(onSuccess, onError, vrm, vehicleMake, vehicleModel, siteName, driverName, driverMobileNumber, startDate, endDate, reason, siteId, anprCameraId, roi, orgId, vrmId, token) {

        let body = JSON.stringify({
            "vrm": vrm,
            "vehicleMake": vehicleMake,
            "vehicleModel": vehicleModel,
            "siteName": siteName,
            "driverName": driverName,
            "driverMobileNumber": driverMobileNumber,
            "startDate": startDate,
            "endDate": endDate,
            "siteId": siteId,
            anprCameraId,
            roi,
            "notes": reason
        });

        this.put('/' + orgId + "/allowed-vrms/" + vrmId, body, onSuccess, onError, token);
    },
    deleteAllowedVrm(onSuccess, orgId, allowedVrmId, token) {
        this.delete('/' + orgId + '/allowed-vrms/' + allowedVrmId, onSuccess, null, token);
    },

    getTags(onSuccess, onError, organisationId, token) {
        this.get('/' + organisationId + '/tags', onSuccess, onError, token);
    },

    getTag(onSuccess, onError, orgId, tagId, token) {
        this.get('/' + orgId + '/tags/' + tagId, onSuccess, onError, token);
    },

    addTag(onSuccess, onError, tagName, tagColour, orgId, token) {

        let body = JSON.stringify({
            "name": tagName,
            "colourCode": tagColour,
        });

        this.put('/' + orgId + "/tags/add", body, onSuccess, onError, token);
    },

    editTag(onSuccess, onError, tagName, tagColour, tagId, orgId, token) {

        let body = JSON.stringify({
            "name": tagName,
            "colourCode": tagColour,
            "id": tagId,
        });

        this.put('/' + orgId + "/tags/" + tagId, body, onSuccess, onError, token);
    },

    deleteTag(onSuccess, orgId, tagId, token) {
        this.delete('/' + orgId + '/tags/' + tagId, onSuccess, null, token);
    },

    addClientTag(onSuccess, onError, orgId, clientId, tagId, token) {
        this.put('/' + orgId + "/clients/" + clientId + '/tags/' + tagId, null, onSuccess, onError, token);
    },

    deleteClientTag(onSuccess, onError, orgId, clientId, tagId, token) {
        this.delete('/' + orgId + "/clients/" + clientId + '/tags/' + tagId, onSuccess, onError, token);
    },

    addSiteTag(onSuccess, onError, orgId, siteId, tagId, token) {
        this.put('/' + orgId + "/sites/" + siteId + '/tags/' + tagId, null, onSuccess, onError, token);
    },

    deleteSiteTag(onSuccess, onError, orgId, siteId, tagId, token) {
        this.delete('/' + orgId + "/sites/" + siteId + '/tags/' + tagId, onSuccess, onError, token);
    },

    getContraventions(onSuccess, onError, organisationId, token) {
        this.get('/' + organisationId + '/contraventions', onSuccess, onError, token);
    },

    getContravention(onSuccess, onError, organisationId, contraventionId, token) {
        this.get('/' + organisationId + '/contraventions/' + contraventionId, onSuccess, onError, token);
    },

    addContravention(onSuccess, onError, name, dvlaCode, type, status, appealReply, orgId, token) {

        let body = JSON.stringify({
            "name": name,
            "dvlaCode": dvlaCode,
            "contraventionType": type,
            "appealReply": "string",
            "isActive": status,
            "appealReply": appealReply,
        });

        this.put('/' + orgId + "/contraventions/add", body, onSuccess, onError, token);
    },

    editContravention(onSuccess, onError, name, dvlaCode, type, status, appealReply, orgId, contraventionId, token) {

        let body = JSON.stringify({
            "name": name,
            "dvlaCode": dvlaCode,
            "contraventionType": type,
            "appealReply": "string",
            "isActive": status,
            "appealReply": appealReply
        });

        this.put('/' + orgId + "/contraventions/" + contraventionId, body, onSuccess, onError, token);
    },

    deleteContravention(onSuccess, orgId, contraventionId, token) {
        this.delete('/' + orgId + '/contraventions/' + contraventionId, onSuccess, null, token);
    },

    getKioskDevices(onSuccess, onError, orgId, token) {
        this.get('/' + orgId + '/kiosk-devices', onSuccess, onError, token);
    },

    getSiteCameras(onSuccess, onError, orgId, siteId, token) {
        this.get('/' + orgId + '/sites/' + siteId + '/anpr-cameras', onSuccess, onError, token);
    },

    getCameras(onSuccess, onError, orgId, token) {
        this.get('/' + orgId + '/anpr-cameras', onSuccess, onError, token);
    },

    getCameraHeartbeats(onSuccess, onError, cameraId, pageNumber, orgId, token) {
        this.get('/' + orgId + '/anpr-cameras/' + cameraId + '/heartbeats/' + pageNumber, onSuccess, onError, token);
    },

    getCamera(onSuccess, onError, orgId, cameraId, token) {
        this.get('/' + orgId + '/anpr-cameras/' + cameraId, onSuccess, onError, token);
    },

    addCamera(onSuccess, onError, name, macAddress, ipAddress, inTag, outTag, isExclusionCamera, isBayMonitoringCamera, status, orgId, siteId, token) {

        let body = JSON.stringify({
            "name": name,
            "macAddress": macAddress,
            "ipAddress": ipAddress,
            "inTag": inTag,
            "outTag": outTag,
            "isExclusionCamera": isExclusionCamera,
            isBayMonitoringCamera,
            "isActive": status,
            "siteId": siteId,
        });

        this.put('/' + orgId + "/anpr-cameras/add", body, onSuccess, onError, token);
    },

    editCamera(onSuccess, onError, name, macAddress, ipAddress, inTag, outTag, isExclusionCamera, isBayMonitoringCamera, status, orgId, cameraId, siteId, token) {

        let body = JSON.stringify({
            "name": name,
            "macAddress": macAddress,
            "ipAddress": ipAddress,
            "inTag": inTag,
            "outTag": outTag,
            "isExclusionCamera": isExclusionCamera,
            isBayMonitoringCamera,
            "isActive": status,
            "siteId": siteId,
        });

        this.put('/' + orgId + "/anpr-cameras/" + cameraId, body, onSuccess, onError, token);
    },

    deleteCamera(onSuccess, orgId, cameraId, token) {
        this.delete('/' + orgId + '/anpr-cameras/' + cameraId, onSuccess, null, token);
    },

    getOrganisationSettings(onSuccess, onError, orgId, token) {
        this.get('/' + orgId + '/settings', onSuccess, onError, token);
    },

    editOrganisationSettings(onSuccess, onError, parkonomyApiKey, iasPrivateHash, iasPublicHash, paymentProvider,
        stripePublishableKey, stripeSecretKey, titleColor, textColor, backgroundColor, titleBackgroundColor, customCss, logoId, hostName, kadoeApplicationId, kadoeEnquirerId, kadoeIntermediaryId, ringGoApiPrivateKey, ringGoApiPublicKey, flowbirdApiUsername, flowbirdApiPassword, orgId, token) {

        let body = JSON.stringify({
            parkonomyApiKey,
            iasPublicHash,
            iasPrivateHash,
            paymentProvider,
            stripePublishableKey,
            stripeSecretKey,
            "managePcnLogoAttachmentId": logoId,
            "managePcnTextColour": textColor,
            "managePcnTitleColour": titleColor,
            "managePcnBackgroundColour": backgroundColor,
            "managePcnTitleBackgroundColour": titleBackgroundColor,
            "managePcnCustomCss": customCss,
            "managePcnHostName": hostName,
            kadoeApplicationId,
            kadoeEnquirerId,
            kadoeIntermediaryId,
            ringGoApiPrivateKey,
            ringGoApiPublicKey,
            flowbirdApiUsername,
            flowbirdApiPassword
        });

        this.put('/' + orgId + "/settings", body, onSuccess, onError, token);
    },

    getPayDisplays(onSuccess, onError, orgId, token) {
        this.get('/' + orgId + '/pay-and-display-machines', onSuccess, onError, token);
    },

    getSitePayDisplays(onSuccess, onError, siteId, orgId, token) {
        this.get('/' + orgId + '/sites/' + siteId + '/pay-and-display-machines', onSuccess, onError, token);
    },

    getPayDisplay(onSuccess, onError, payDisplayId, orgId, token) {
        this.get('/' + orgId + '/pay-and-display-machines/' + payDisplayId, onSuccess, onError, token);
    },

    addPayDisplay(onSuccess, onError, name, cityCode, machineId, flowbirdTerminalId, status, siteId, orgId, token) {

        let body = JSON.stringify({
            siteId,
            name,
            cityCode,
            machineId,
            flowbirdTerminalId,
            "isActive": status,
        });

        this.put('/' + orgId + "/pay-and-display-machines/add", body, onSuccess, onError, token);
    },

    editPayDisplay(onSuccess, onError, payDisplayId, name, cityCode, machineId, flowbirdTerminalId, status, siteId, orgId, token) {

        let body = JSON.stringify({
            siteId,
            name,
            cityCode,
            machineId,
            flowbirdTerminalId,
            "isActive": status,
        });

        this.put('/' + orgId + "/pay-and-display-machines/" + payDisplayId, body, onSuccess, onError, token);
    },

    deletePayDisplay(onSuccess, orgId, payDisplayId, token) {
        this.delete('/' + orgId + '/pay-and-display-machines/' + payDisplayId, onSuccess, null, token);
    },

    getCameraData(onSuccess, onError, search, siteId, fromDate, toDate, pageNumber, orgId, token, signal) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);
        var raw = JSON.stringify({
            "searchQuery": search,
            "siteId": siteId,
            "fromDate": fromDate,
            "toDate": toDate,
            "pageNumber": pageNumber
        });
        if (_cameraDataAbortController) {
            _cameraDataAbortController.abort()
        }
        _cameraDataAbortController = new AbortController();

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
            signal: _cameraDataAbortController.signal
        };

        fetch(baseUrl + '/' + orgId + "/anpr-camera-decodes/search", requestOptions)
            .then(response => response.json())
            .then(data => {
                if (onSuccess) {
                    onSuccess(data);
                }
            }).catch((error) => {
                if (onError && error.name !== "AbortError") {
                    onError(error);
                }
            });
    },

    editCameraData(onSuccess, onError, organisationId, anprCameraReadId, vrm, token) {

        let body = JSON.stringify({
            "vrm": vrm
        });

        this.put('/' + organisationId + '/anpr-camera-decodes/' + anprCameraReadId, body, onSuccess, onError, token);
    },

    redact(onSuccess, onError, imageWidth, imageHeight, redactions, orgId, decodeId, token) {

        let body = JSON.stringify({
            "imageWidth": imageWidth,
            "imageHeight": imageHeight,
            "redactions": redactions,
        });

        this.post('/' + orgId + "/anpr-camera-decodes/" + decodeId + '/redact', body, onSuccess, onError, token);
    },

    redactAttachment(onSuccess, onError, imageWidth, imageHeight, redactions, orgId, attachmentId, token) {

        let body = JSON.stringify({
            "imageWidth": imageWidth,
            "imageHeight": imageHeight,
            "redactions": redactions,
        });

        this.post('/' + orgId + "/attachments/" + attachmentId + '/redact', body, onSuccess, onError, token);
    },

    voidTicket(onSuccess, onError, breachId, orgId, token) {

        let body = JSON.stringify({
            "note": "ticket voided",
        });

        this.post('/' + orgId + "/breaches/" + breachId + "/void", body, onSuccess, onError, token);
    },

    voidMultipleTickets(onSuccess, onError, breachIds, note, orgId, token) {

        let body = JSON.stringify({
            "breachStatus": 503,
            "note": note,
            breachIds: breachIds
        });

        this.post('/' + orgId + "/breaches/" + "set-status", body, onSuccess, onError, token);
    },

    onHoldTicket(onSuccess, onError, note, breachId, onHold, orgId, token) {

        let body = JSON.stringify({
            "note": note,
            "isOnHold": onHold,
        });

        this.post('/' + orgId + "/breaches/" + breachId + "/hold", body, onSuccess, onError, token);
    },

    setBreachStatus(onSuccess, onError, note, breachId, breachStatus, orgId, token) {

        let body = JSON.stringify({
            "note": note,
            "breachStatus": breachStatus,
        });

        this.post('/' + orgId + "/breaches/" + breachId + "/set-status", body, onSuccess, onError, token);
    },

    setBreachReducedCharge(onSuccess, onError, breachId, reducedChargeDays, reducedChargeAmount, orgId, token) {

        let body = JSON.stringify({
            reducedChargeDays,
            reducedChargeAmount
        });

        this.post(`/${orgId}/breaches/${breachId}/set-reduced-charge`, body, onSuccess, onError, token);
    },

    assignTickets(onSuccess, onError, userId, breachIds, orgId, token) {

        let body = JSON.stringify({
            "userId": userId,
            "breachIds": breachIds,
        });

        this.post('/' + orgId + "/breaches/assign", body, onSuccess, onError, token);
    },

    attachNoteToTicket(onSuccess, onError, note, breachId, orgId, token) {

        let body = JSON.stringify({
            "note": note,
        });

        this.post('/' + orgId + "/breaches/" + breachId + '/note', body, onSuccess, onError, token);
    },

    getLog(onSuccess, onError, breachId, orgId, token) {
        this.get('/' + orgId + '/breaches/' + breachId + '/log', onSuccess, onError, token);
    },

    sendTicketToDvla(onSuccess, onError, orgId, breachId, token) {
        this.post('/' + orgId + "/breaches/" + breachId + "/dvla-request", null, onSuccess, onError, token);
    },

    cancelTicket(onSuccess, onError, reasonId, orgId, breachId, token) {

        let body = JSON.stringify({
            "cancellationReasonId": reasonId,
        });

        this.post('/' + orgId + "/breaches/" + breachId + "/cancel", body, onSuccess, onError, token);
    },

    addInvitation(onSuccess, onError, emailAddress, role, clientId, siteIds, orgId, token) {

        let body = JSON.stringify({
            "emailAddress": emailAddress,
            "organisationId": orgId,
            "role": role,
            "clientId": clientId,
            "siteIds": siteIds
        });

        this.post('/invitations/add', body, onSuccess, onError, token);
    },

    getInvitations(onSuccess, onError, pageNumber, orgId, token) {

        let body = JSON.stringify({
            "organisationId": orgId,
            "pageNumber": pageNumber,
        });

        this.post('/invitations/search', body, onSuccess, onError, token);
    },

    resendInvitation(onSuccess, onError, inviteId, token) {
        this.post('/invitations/' + inviteId + '/resend', null, onSuccess, onError, token);
    },

    revokeInvitation(onSuccess, onError, inviteId, token) {
        this.delete('/invitations/' + inviteId, onSuccess, onError, token);
    },

    getInvitation(onSuccess, onError, inviteId, token) {
        this.get('/invitations/' + inviteId, onSuccess, onError, token);
    },

    acceptInvitation(onSuccess, onError, first, last, emailAddress, userId, inviteId, token) {

        let body = JSON.stringify({
            "userId": userId,
            "emailAddress": emailAddress,
            "firstName": first,
            "lastName": last,
        });

        this.post('/invitations/' + inviteId + '/accept', body, onSuccess, onError, token);
    },

    addCancellationReason(onSuccess, onError, name, description, isActive, orgId, token) {

        let body = JSON.stringify({
            "name": name,
            "description": description,
            "isActive": isActive,
        });

        this.put('/' + orgId + '/cancellation-reasons/add', body, onSuccess, onError, token);
    },

    getCancellationReasons(onSuccess, onError, organisationId, token) {
        this.get('/' + organisationId + '/cancellation-reasons', onSuccess, onError, token);
    },

    getCancellationReason(onSuccess, onError, reasonId, organisationId, token) {
        this.get('/' + organisationId + '/cancellation-reasons/' + reasonId, onSuccess, onError, token);
    },

    editCancellationReason(onSuccess, onError, name, description, isActive, reasonId, orgId, token) {

        let body = JSON.stringify({
            "name": name,
            "description": description,
            "isActive": isActive,
        });

        this.put('/' + orgId + '/cancellation-reasons/' + reasonId, body, onSuccess, onError, token);
    },

    deleteCancellationReason(onSuccess, reasonId, orgId, token) {
        this.delete('/' + orgId + '/cancellation-reasons/' + reasonId, onSuccess, null, token);
    },

    getContactDetails(onSuccess, onError, breachId, organisationId, token) {
        this.get('/' + organisationId + '/breaches/' + breachId + "/contact-details", onSuccess, onError, token);
    },

    editContactDetails(onSuccess, onError, firstName, lastName, title, companyName, emailAddress, contactNumber, contactType, contactSource, addressLine1, addressLine2, addressLine3, townOrCity, postCode, country, isActive, fileId, detailsId, breachId, orgId, token) {

        let body = JSON.stringify({
            "firstName": firstName,
            "lastName": lastName,
            "title": title,
            "companyName": companyName,
            "emailAddress": emailAddress,
            "mobilePhoneNumber": contactNumber,
            "contactType": contactType,
            "contactSource": contactSource,
            "addressLine1": addressLine1,
            "addressLine2": addressLine2,
            "addressLine3": addressLine3,
            "townOrCity": townOrCity,
            "postCode": postCode,
            "country": country,
            "isActive": isActive,
            "attachmentId": fileId,
        });

        this.put('/' + orgId + '/breaches/' + breachId + '/contact-details/' + detailsId, body, onSuccess, onError, token);
    },

    addContactDetails(onSuccess, onError, firstName, lastName, title, companyName, emailAddress, contactNumber, contactType, contactSource, addressLine1, addressLine2, addressLine3, townOrCity, postCode, country, isActive, fileId, breachId, orgId, token) {

        let body = JSON.stringify({
            "firstName": firstName,
            "lastName": lastName,
            "title": title,
            "companyName": companyName,
            "emailAddress": emailAddress,
            "mobilePhoneNumber": contactNumber,
            "contactType": contactType,
            "contactSource": contactSource,
            "addressLine1": addressLine1,
            "addressLine2": addressLine2,
            "addressLine3": addressLine3,
            "townOrCity": townOrCity,
            "postCode": postCode,
            "country": country,
            "isActive": isActive,
            "attachmentId": fileId,
        });

        this.put('/' + orgId + '/breaches/' + breachId + '/contact-details/add', body, onSuccess, onError, token);
    },

    deleteContactDetails(onSuccess, detailsId, breachId, orgId, token) {
        this.delete('/' + orgId + '/breaches/' + breachId + "/contact-details/" + detailsId, onSuccess, null, token);
    },

    redactContactDetails(onSuccess, detailsId, breachId, orgId, token) {
        this.post('/' + orgId + '/breaches/' + breachId + "/contact-details/" + detailsId + "/redact", null, onSuccess, null, token);
    },

    wardenVehicleLookup(onSuccess, vrm, orgId, token) {
        this.get('/' + orgId + '/vehicle-lookup/' + vrm, onSuccess, null, token);
    },

    editBreach(onSuccess, vrm, lookupMake, lookupModel, lookupColour, contraventionId, breachId, orgId, token) {

        let body = JSON.stringify({
            "vrm": vrm,
            "vehicleMake": lookupMake,
            "vehicleModel": lookupModel,
            "vehicleColour": lookupColour,
            "contraventionId": contraventionId,
        });

        this.put('/' + orgId + '/breaches/' + breachId, body, onSuccess, null, token);
    },

    getTransfersLiability(onSuccess, onError, breachId, organisationId, token) {
        this.get('/' + organisationId + '/breaches/' + breachId + "/transfer-of-liability", onSuccess, onError, token);
    },

    acceptTransferLiability(onSuccess, onError, breachId, contactId, orgId, token) {
        this.post('/' + orgId + '/breaches/' + breachId + '/transfer-of-liability/' + contactId + '/accept', null, onSuccess, onError, token);
    },

    declineTransferLiability(onSuccess, onError, breachId, contactId, orgId, token) {
        this.post('/' + orgId + '/breaches/' + breachId + '/transfer-of-liability/' + contactId + '/decline', null, onSuccess, onError, token);
    },

    addTransferLiability(onSuccess, onError, notifier, liabilityParty, breachId, orgId, token) {

        let body = JSON.stringify({
            "notifier": notifier,
            "liabilityParty": liabilityParty,
        });

        this.put('/' + orgId + '/breaches/' + breachId + '/transfer-of-liability/add', body, onSuccess, onError, token);
    },

    getAppeals(onSuccess, onError, breachId, organisationId, token) {
        this.get('/' + organisationId + '/breaches/' + breachId + "/appeals", onSuccess, onError, token);
    },

    getAppeal(onSuccess, onError, breachId, appealId, organisationId, token) {
        this.get('/' + organisationId + '/breaches/' + breachId + "/appeals/" + appealId, onSuccess, onError, token);
    },

    deleteAppeal(onSuccess, onError, breachId, appealId, organisationId, token) {
        this.delete('/' + organisationId + '/breaches/' + breachId + "/appeals/" + appealId, onSuccess, onError, token);
    },

    acceptAppeal(onSuccess, onError, breachId, appealId, appealResponse, orgId, token) {
        this.post('/' + orgId + '/breaches/' + breachId + '/appeals/' + appealId + '/accept', JSON.stringify({ appealResponse }), onSuccess, onError, token);
    },

    declineAppeal(onSuccess, onError, breachId, appealId, appealResponse, orgId, token) {
        this.post('/' + orgId + '/breaches/' + breachId + '/appeals/' + appealId + '/decline', JSON.stringify({ appealResponse }), onSuccess, onError, token);
    },

    addAppeal(onSuccess, onError, appealantDetails, appealReason, attachmentIds, breachId, orgId, token) {

        let body = JSON.stringify({
            "appealantDetails": appealantDetails,
            "appealReason": appealReason,
            "attachmentIds": attachmentIds,
        });

        this.put('/' + orgId + '/breaches/' + breachId + '/appeals/add', body, onSuccess, onError, token);
    },

    getLetterTemplates(onSuccess, onError, organisationId, token) {
        this.get('/' + organisationId + '/letter-templates', onSuccess, onError, token);
    },

    getLetterTemplate(onSuccess, onError, templateId, organisationId, token) {
        this.get('/' + organisationId + '/letter-templates/' + templateId, onSuccess, onError, token);
    },

    addLetterTemplate(onSuccess, onError,
        additionalChargeType, additionalCharge, debtRecoveryCharge, debtRecoveryChargeType,
        completeStatus, resetCharges, resetDateIssued, processOnMondays, processOnTuesdays,
        processOnWednesdays, processOnThursdays, processOnFridays, processOnSaturdays,
        processOnSundays, processOnPublicHoildays, processingTime, contraventions, attachmentId, name, items, isActive, orgId, token) {

        let body = JSON.stringify({
            "attachmentId": attachmentId,
            "name": name,
            "isActive": isActive,
            "items": items,
            "additionalCharge": additionalCharge,
            "additionalChargeType": additionalChargeType,
            "debtRecoveryCharge": debtRecoveryCharge,
            "debtRecoverChargeType": debtRecoveryChargeType,
            "completeStatus": completeStatus,
            "resetCharges": resetCharges,
            "resetDateIssued": resetDateIssued,
            "processOnPublicHoildays": processOnPublicHoildays,
            "processOnMondays": processOnMondays,
            "processOnTuesdays": processOnTuesdays,
            "processOnWednesdays": processOnWednesdays,
            "processOnThursdays": processOnThursdays,
            "processOnFridays": processOnFridays,
            "processOnSaturdays": processOnSaturdays,
            "processOnSundays": processOnSundays,
            "processingTime": processingTime,
            "contraventions": contraventions,
        });

        this.put('/' + orgId + '/letter-templates/add', body, onSuccess, onError, token);
    },

    editLetterTemplate(onSuccess, onError,
        additionalChargeType, additionalCharge, debtRecoveryCharge, debtRecoveryChargeType,
        completeStatus, resetCharges, resetDateIssued, processOnMondays, processOnTuesdays,
        processOnWednesdays, processOnThursdays, processOnFridays, processOnSaturdays,
        processOnSundays, processOnPublicHoildays, processingTime, contraventions, templateId, attachmentId, name, items, isActive, orgId, token) {

        let body = JSON.stringify({
            "attachmentId": attachmentId,
            "name": name,
            "isActive": isActive,
            "items": items,
            "additionalCharge": additionalCharge,
            "additionalChargeType": additionalChargeType,
            "debtRecoveryCharge": debtRecoveryCharge,
            "debtRecoverChargeType": debtRecoveryChargeType,
            "completeStatus": completeStatus,
            "resetCharges": resetCharges,
            "resetDateIssued": resetDateIssued,
            "processOnPublicHoildays": processOnPublicHoildays,
            "processOnMondays": processOnMondays,
            "processOnTuesdays": processOnTuesdays,
            "processOnWednesdays": processOnWednesdays,
            "processOnThursdays": processOnThursdays,
            "processOnFridays": processOnFridays,
            "processOnSaturdays": processOnSaturdays,
            "processOnSundays": processOnSundays,
            "processingTime": processingTime,
            "contraventions": contraventions,
        });

        this.put('/' + orgId + '/letter-templates/' + templateId, body, onSuccess, onError, token);
    },

    previewLetterTemplate(onSuccess, onError, attachmentId, items, showOutOfBounds, showGridLines, orgId, token) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', 'Bearer ' + token);

        var raw = JSON.stringify({
            "attachmentId": attachmentId,
            "items": items,
            "showOutOfBoundsAreas": showOutOfBounds,
            "showGridLines": showGridLines,
        });

        if (_previewAbortController) {
            _previewAbortController.abort()
        }
        _previewAbortController = new AbortController();

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            responseType: 'blob',
            redirect: 'follow',
            signal: _previewAbortController.signal
        };

        fetch(baseUrl + '/' + orgId + '/letter-templates/preview', requestOptions)
            .then(this.handleErrors)
            .then(function (response) {
                return response.blob();
            }).then(function (blob) {
                onSuccess(blob);
            }).catch((error) => {
                onError(error)
            });
    },

    deleteLetterTemplate(onSuccess, onError, templateId, orgId, token) {
        this.delete('/' + orgId + '/letter-templates/' + templateId, onSuccess, onError, token);
    },

    getTemplatePlaceholders(onSuccess, onError, organisationId, token) {
        this.get('/' + organisationId + '/letter-templates/placeholders', onSuccess, onError, token);
    },

    getTemplateCondition(onSuccess, onError, conditionId, templateId, organisationId, token) {
        this.get('/' + organisationId + '/letter-templates/' + templateId + '/conditions/' + conditionId, onSuccess, onError, token);
    },

    editTemplateCondition(onSuccess, onError, conditionId, templateId, conditionType, argument, orgId, token) {

        let body = JSON.stringify({
            "letterTemplateConditionType": conditionType,
            "argument": argument
        });

        this.put('/' + orgId + '/letter-templates/' + templateId + '/conditions/' + conditionId, body, onSuccess, onError, token);
    },

    addTemplateCondition(onSuccess, onError, templateId, conditionType, argument, orgId, token) {

        let body = JSON.stringify({
            "letterTemplateConditionType": conditionType,
            "argument": argument
        });

        this.put('/' + orgId + '/letter-templates/' + templateId + '/conditions/add', body, onSuccess, onError, token);
    },

    deleteTemplateCondition(onSuccess, onError, conditionId, templateId, orgId, token) {
        this.delete('/' + orgId + '/letter-templates/' + templateId + '/conditions/' + conditionId, onSuccess, onError, token);
    },

    searchRingGo(onSuccess, onError, vrm, similarityThreshold, fromDate, toDate, siteId, orgId, token) {
        let body = JSON.stringify({
            "vrm": vrm,
            "similarityThreshold": similarityThreshold,
            "fromDate": fromDate,
            "toDate": toDate,
            siteId
        });
        this.post('/' + orgId + '/ringgo/search', body, onSuccess, onError, token);
    },

    searchParkonomy(onSuccess, onError, vrm, similarityThreshold, fromDate, toDate, siteId, orgId, token) {
        let body = JSON.stringify({
            "vrm": vrm,
            "similarityThreshold": similarityThreshold,
            "fromDate": fromDate,
            "toDate": toDate,
            siteId
        });
        this.post('/' + orgId + '/parkonomy/search', body, onSuccess, onError, token);
    },

    searchParkfolio(onSuccess, onError, vrm, similarityThreshold, fromDate, toDate, siteId, orgId, token) {
        let body = JSON.stringify({
            "vrm": vrm,
            "similarityThreshold": similarityThreshold,
            "fromDate": fromDate,
            "toDate": toDate,
            siteId
        });
        this.post('/' + orgId + '/parkfolio/search', body, onSuccess, onError, token);
    },

    searchFlowbird(onSuccess, onError, vrm, similarityThreshold, fromDate, toDate, siteId, orgId, token) {
        let body = JSON.stringify({
            "vrm": vrm,
            "similarityThreshold": similarityThreshold,
            "fromDate": fromDate,
            "toDate": toDate,
            siteId
        });
        this.post('/' + orgId + '/flowbird/search', body, onSuccess, onError, token);
    },

    searchAllowedVrms(onSuccess, onError, vrm, similarityThreshold, siteId, orgId, token) {
        let body = JSON.stringify({
            vrm,
            similarityThreshold,
            siteId
        });
        this.post(`/${orgId}/allowed-vrms/search`, body, onSuccess, onError, token);
    },

    /* REPORTS */

    getBreachReports(onSuccess, onError, breachStatus, date, reportFormat, orgId, token) {
        if (reportFormat === 0) {
            this.post(`/${orgId}/reports/breaches`, JSON.stringify({ breachStatus, date, reportFormat }), onSuccess, onError, token);
        } else if (reportFormat === 1) {
            this.postForBlob(`/${orgId}/reports/breaches`, JSON.stringify({ breachStatus, date, reportFormat }), onSuccess, onError, token);
        }
    },

    getPcnsIssuedReports(onSuccess, onError, from, to, reportFormat, orgId, token) {
        if (reportFormat === 0) {
            this.post(`/${orgId}/reports/pcns-issued`, JSON.stringify({ reportFormat, from, to }), onSuccess, onError, token);
        } else if (reportFormat === 1) {
            this.postForBlob(`/${orgId}/reports/pcns-issued`, JSON.stringify({ reportFormat, from, to }), onSuccess, onError, token);
        }
    },

    getPcnsPaidReports(onSuccess, onError, from, to, reportFormat, orgId, token) {
        if (reportFormat === 0) {
            this.post(`/${orgId}/reports/pcns-paid`, JSON.stringify({ reportFormat, from, to }), onSuccess, onError, token);
        } else if (reportFormat === 1) {
            this.postForBlob(`/${orgId}/reports/pcns-paid`, JSON.stringify({ reportFormat, from, to }), onSuccess, onError, token);
        }
    },

    getKadoeRequests(onSuccess, onError, query, from, to, page, orgId, token) {
        this.post(`/${orgId}/kadoe-requests/search`, JSON.stringify({ query, from, to, page }), onSuccess, onError, token);
    },
}
var _previewAbortController = null;
var _dashSearchAbortController = null;
var _cameraDataAbortController = null;