import React from 'react';
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";

import authentication from '../authentication/b2c';
import Gravatar from './gravatar';
import roleUtilities from '../../utilities/roleUtilities';

function Header(props) {
    const orgsPrefix = "/organisation/" + props.organisationId;
    const orgsUrl = orgsPrefix + "/organisations";
    const orgUrl = "/organisation/" + props.organisationId;
    const breachesUrl = orgsPrefix + "/breaches";
    const clientsUrl = orgsPrefix + "/clients";
    const sitesUrl = orgsPrefix + "/sites";
    const enforcementUrl = orgsPrefix + "/enforcement";
    const allowListUrl = orgsPrefix + "/allow-list";
    const cancellationUrl = orgsPrefix + "/cancellation-reasons";
    const cameraRawDataUrl = orgsPrefix + "/raw-data/camera-data";
    const kadoeRawDataUrl = orgsPrefix + "/raw-data/kadoe";
    const contraventionsUrl = orgsPrefix + "/contravention-settings";
    const tagSettingsUrl = orgsPrefix + "/tag-settings";
    const refNumRangesUrl = orgsPrefix + "/reference-number-ranges";
    const userManagUrl = orgsPrefix + "/user-management";
    const letterTemplatesUrl = orgsPrefix + "/letter-templates";

    return (
        <>
            <nav className="navbar navbar-light navbar-glass navbar-top sticky-kit navbar-expand-lg">
                <button className="btn navbar-toggler-humburger-icon navbar-toggler mr-1 mr-sm-3" type="button" data-toggle="collapse" data-target="#navbarStandard" aria-controls="navbarStandard" aria-expanded="false" aria-label="Toggle Navigation"><span className="navbar-toggle-icon"><span className="toggle-line"></span></span></button>
                <a className="navbar-brand mr-1 mr-sm-3" href="/">
                    <div className="d-flex align-items-center"><img className="mr-2" src="/assets/img/apt-logos/logo.svg" alt="" width="100" />
                    </div>
                </a>
                <div className="collapse navbar-collapse" id="navbarStandard">
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown dropdown-on-hover">
                            <Link to="/" className="nav-link dropdown-toggle" id="navbarDropdownDash" role="button" >Dashboard</Link>
                            <div className="dropdown-menu dropdown-menu-card" aria-labelledby="navbarDropdownDash">
                                <div className="bg-white rounded-soft py-2">
                                    <Link className="dropdown-item" to={breachesUrl}>Breaches</Link>
                                </div>
                            </div>
                        </li>
                        {props.user.isGlobalAdmin &&
                            <li className="nav-item">
                                <Link to={orgsUrl} className="nav-link" role="button" >Organisations</Link>
                            </li>}
                        <li className="nav-item dropdown dropdown-on-hover">
                            <Link to={orgUrl} className="nav-link dropdown-toggle" id="navbarDropdownOrganisation" role="button" >{props.organisationName}</Link>
                            <div className="dropdown-menu dropdown-menu-card" aria-labelledby="navbarDropdownOrganisation">
                                <div className="bg-white rounded-soft py-2">
                                    <Link className="dropdown-item" to={allowListUrl}>Allow List</Link>
                                </div>
                                <div className="bg-white rounded-soft py-2">
                                    <Link className="dropdown-item" to={cancellationUrl}>Cancellation Reasons</Link>
                                </div>
                                <div className="bg-white rounded-soft py-2">
                                    <Link className="dropdown-item" to={contraventionsUrl}>Contravention Settings</Link>
                                </div>
                                <div className="bg-white rounded-soft py-2">
                                    <Link className="dropdown-item" to={letterTemplatesUrl}>Letter Templates</Link>
                                </div>
                                <div className="bg-white rounded-soft py-2">
                                    <Link className="dropdown-item" to={refNumRangesUrl}>Reference Number Ranges</Link>
                                </div>
                                <div className="bg-white rounded-soft py-2">
                                    <Link className="dropdown-item" to={tagSettingsUrl}>Tag Settings</Link>
                                </div>
                                <div className="bg-white rounded-soft py-2">
                                    <Link className="dropdown-item" to={userManagUrl}>User Management</Link>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item">
                            <Link to={clientsUrl} className="nav-link">Clients</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={sitesUrl} className="nav-link">Sites</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={enforcementUrl} className="nav-link">Enforcement</Link>
                        </li>

                        <li className="nav-item dropdown dropdown-on-hover">
                            <div className="nav-link dropdown-toggle" id="navbarDropdownRawData" role="button" >Raw Data</div>
                            <div className="dropdown-menu dropdown-menu-card" aria-labelledby="navbarDropdownRawData">
                                <div className="bg-white rounded-soft py-2">
                                    <Link className="dropdown-item" to={cameraRawDataUrl}>Camera Data</Link>
                                </div>
                                <div className="bg-white rounded-soft py-2">
                                    <Link className="dropdown-item" to={kadoeRawDataUrl}>Kadoe Requests</Link>
                                </div>
                            </div>
                        </li>

                        {
                            roleUtilities.isOrganisatoinAdminOrManager(props.user, props.organisationId) &&
                                <li className="nav-item dropdown dropdown-on-hover">
                                    <div className="nav-link dropdown-toggle" id="navbarDropdownReports" role="button" >Reports</div>
                                    <div className="dropdown-menu dropdown-menu-card" aria-labelledby="navbarDropdownReports">
                                        <div className="bg-white rounded-soft py-2">
                                            <Link className="dropdown-item" to={`${orgsPrefix}/reports/export-breaches`}>Export Breaches</Link>
                                        </div>
                                        <div className="bg-white rounded-soft py-2">
                                            <Link className="dropdown-item" to={`${orgsPrefix}/reports/pcns-issued`}>PCNs Issued</Link>
                                        </div>
                                        <div className="bg-white rounded-soft py-2">
                                            <Link className="dropdown-item" to={`${orgsPrefix}/reports/pcns-paid`}>PCNs Paid</Link>
                                        </div>
                                    </div>
                                </li>
                        }
                    </ul>
                </div>
                <ul className="navbar-nav navbar-nav-icons ml-auto flex-row align-items-center">
                    <li className="nav-item dropdown dropdown-on-hover">
                        <a className="nav-link pr-0 user-icon" id="navbarDropdownUser" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {props.user !== null && props.user !== undefined &&
                                <Gravatar user={props.user} />
                            }
                        </a>
                        <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="navbarDropdownUser">
                            <div className="bg-white rounded-soft py-2">
                                {props.user !== undefined && (props.user.organisations.length > 1 || props.user.isGlobalAdmin) &&
                                    <>
                                        <a className="dropdown-item" href="/">Select Organisation</a>
                                        <div className="dropdown-divider"></div>
                                    </>}
                                <Link className="dropdown-item" to="/profile-management">Profile Management</Link>
                                <div className="dropdown-divider"></div>
                                <button type="button" className="dropdown-item" data-toggle="modal" data-target="#signOutModal">Sign Out</button>
                            </div>
                        </div>
                    </li>
                </ul>
            </nav>
            <div className="modal fade" id="signOutModal" tabIndex="-1" role="dialog" aria-labelledby="signOutModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="signOutModalLabel">Are you sure you want to Sign Out?</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                            <button className="btn btn-primary btn-sm" data-dismiss="modal" onClick={() => authentication.signOut()}>Sign Out</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Header;