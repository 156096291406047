export const ReportsWrapper = (props) => {

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row justify-content-between">
                                <div className="col-4">
                                    <h5 className="mb-0">
                                        {props.title}
                                    </h5>
                                </div>
                            </div>
                        </div>

                        <div className="card-body border-top details-container">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}