export const DownloadButton = (props) => {
    return (
        <div className="download-button">
            <button onClick={props.handleClick} class="btn btn-sm btn-primary">
                Download {props.reportFormat}
                <i class="fas fa-download ml-2"></i>
            </button>
            {props.downloadError && <p className="text-danger">Error downloading file</p>}
        </div>
    )
}