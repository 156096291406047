import Enums from './enum';
import moment from 'moment';
import { Link } from 'react-router-dom';

export const dataTableCellUtils = (data, key, organisationId) => {

    if (key === "breachStatus") return Enums.BreachStatus[data[key]]

    if (key === "vrm") {
        const breachUrl = '/organisation/' + organisationId + '/breach/' + data.id;
        return <Link to={breachUrl} className="number-plate-link"><h6 className="mb-0 small-number-plate">{data[key]}</h6></Link>
    }

    if (moment(data[key]).isValid()) return moment(data[key]).format("DD/MM/yyyy")
    
    return data[key];
}