import { useEffect, useState } from "react";
import Loader from "../shared/loader";
import Enums from '../../utilities/enum';
import api from '../api/api';
import arrayUtilities from "../../utilities/arrayUtilities";
import { saveAs } from 'file-saver';
import { ReportsWrapper } from "./reportsWrapper";
import { DatePicker } from "../shared/datePicker";
import { DownloadButton } from "./downloadButton";

export default function ExportBreaches(props) {

    const [isLoading, setIsLoading] = useState(true);

    const [breachStatus, setBreachStatus] = useState(100);
    const [date, setDate] = useState(new Date());
    const [error, setError] = useState(false);

    const errorHandling = () => {
        setError(true);
        setTimeout(() => {setError(false)}, 1000)
    }

    const [data, setData] = useState(null);

    const getBreaches = () => {
        setIsLoading(true);
        api.getBreachReports((data) => {
            setData(data);
            setIsLoading(false);
        }, () => {
            setData(null);
            setIsLoading(false);
        }, breachStatus, date, 0, props.organisationId, props.token);
    }

    const handleDownload = (reportFormat) => {
        api.getBreachReports(
            (blob, filename) => { saveAs(blob, filename); },
            errorHandling,
            breachStatus,
            date,
            reportFormat,
            props.organisationId,
            props.token
        );
    }

    useEffect(() => {
        getBreaches();
    }, [breachStatus, date])

    return (
        <ReportsWrapper title="Breaches">
            <div className="reports-filters-container">
                <div className="breach-status-select-container">
                    <label class="col-form-label col-form-label-sm">Breach Status</label>
                    <div className="w-100">
                        <select class="custom-select custom-select-sm" onChange={(e) => setBreachStatus(Number(e.target.value))} value={breachStatus}>
                            {Object.keys(Enums.BreachStatus).map((item) =>
                                <option key={item} value={item}>{Enums.BreachStatus[item]}</option>
                            )}
                        </select>
                    </div>
                </div>

                <DatePicker date={date} title="Date" handleChange={date => setDate(date)} />
                <DownloadButton downloadError={error} reportFormat={Enums.ReportFormat[1]} handleClick={() => handleDownload(1)} />
            </div>
            {
                isLoading
                    ? <Loader />
                    : (
                        <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer mt-5">
                            <thead className="bg-200 text-900">
                                <tr>
                                    <th scope="col">VRM</th>
                                    <th scope="col">Reference Number</th>
                                    <th scope="col">Site Name</th>
                                    <th scope="col">Site Address</th>
                                    <th scope="col">Entry Time</th>
                                    <th scope="col">Entry Date</th>
                                    <th scope="col">Exit Time</th>
                                    <th scope="col">Exit Date</th>
                                    <th scope="col">Duration</th>
                                    <th scope="col">Contravention</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!arrayUtilities.isNullOrEmpty(data) && data.map((dataItem, index) => <tr key={dataItem.referenceNumber} index={index}>
                                    <td>{dataItem.vrm}</td>
                                    <td>{dataItem.referenceNumber}</td>
                                    <td>{dataItem.siteName}</td>
                                    <td>{dataItem.siteAddress}</td>
                                    <td>{dataItem.entryTime}</td>
                                    <td>{dataItem.entryDate}</td>
                                    <td>{dataItem.exitTime}</td>
                                    <td>{dataItem.exitDate}</td>
                                    <td>{dataItem.duration}</td>
                                    <td>{dataItem.contravention}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    )
            }
        </ReportsWrapper>
    );
}