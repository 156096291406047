import { DeviceStatus } from '../../dashboard/deviceStatus';
import { useDevicesState } from "../../../utilities/useDevicesState";
import Enum from '../../../utilities/enum';
import Loader from '../../shared/loader';

export default function AllOrganisationKiosks(props) {
    const { kiosks, getKiosks, kiosksLoading } = useDevicesState(props.organisationId, props.token);

    return (
        <div className="container-fluid">
            {
                kiosks ? (
                    <DeviceStatus
                        orderedDevices={kiosks}
                        deviceType={Enum.DeviceType[1]}
                        viewAll
                        organisationId={props.organisationId}
                        handleRefreshClick={getKiosks}
                        isLoading={kiosksLoading}
                    />
                ) : <Loader />

            }
        </div>
    )
}