import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import Enums from '../../utilities/enum';
import Loader from '../shared/loader';
import moment from 'moment';
import Pagination from '../shared/pagination';
import ChartsPlaceholder from './_chartsPlaceholder';
import Enum from '../../utilities/enum';
import { DeviceStatus } from './deviceStatus';
import { useDevicesState } from '../../utilities/useDevicesState';
import Modal from 'react-bootstrap/Modal';
import StringHelpers from '../../utilities/stringHelpers';
import { useToasts } from 'react-toast-notifications';

function Dashboard(props) {
    const [breaches, setBreaches] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);

    const [sites, setSites] = useState(null);
    const [site, setSite] = useState(null);
    const [siteId, setSiteId] = useState(null);

    const [type, setType] = useState(null);
    const [status, setStatus] = useState(0);
    const [search, setSearch] = useState("");
    const [filterEnd, setFilterEnd] = useState(null);
    const [filterEndTemp, setFilterEndTemp] = useState(null);
    const [filterStart, setFilterStart] = useState(null);
    const [filterStartTemp, setFilterStartTemp] = useState(null);

    const [loading, setLoading] = useState(false);

    const [multiVoidList, setMultiVoidList] = useState([]);
    const [showVoidModal, setShowVoidModal] = useState(false);
    const [note, setNote] = useState("");

    const { addToast } = useToasts();

    const getBreaches = function () {
        Api.getBreaches(
            (data) => {
                setBreaches(data);
                setLoading(false);
            },
            (error) => console.log(error),
            search, siteId, null, null, filterStart, filterEnd, type, status === null ? null : [ status ], pageNumber, 
            props.organisationId,
            props.token, null
        )
    };

    const clearFilters = function () {
        setSearch("");
        setStatus(null);
        setType(null);
        setFilterStart(null);
        setFilterStartTemp(null);
        setFilterEnd(null);
        setFilterEndTemp(null);
        setSiteId(null);
        setSite(null);
    };

    const setFilterDates = function () {
        setFilterStart(filterStartTemp);
        setFilterEnd(filterEndTemp);
    };

    const getSites = function () {
        Api.getSites(
            (data) => setSites(data),
            (error) => console.log(error),
            props.organisationId,
            props.token
        )
    };

    const changePageNumber = function(num){
        setPageNumber(num);
    }

    useEffect(() => {
        setLoading(true);
        getBreaches();
    }, [pageNumber]);

    useEffect(() => {
        if(site !== null){
            setSiteId(site.id);
        } else {setSiteId(null)}
    }, [site]);

    useEffect(() => {
        setLoading(true);

        let doSearchTimeout = setTimeout(() => {
            setPageNumber(0);
            getBreaches();
        }, 1000);

        return () => clearTimeout(doSearchTimeout);
    }, [status, type, search, filterStart, filterEnd, siteId]);

    useEffect(() => {
        getBreaches();
        getSites();
    }, []);

    const {orderedCameras, kiosks, allCamerasUrl, allKiosksUrl, getCameras, getKiosks, camerasLoading, kiosksLoading} = useDevicesState(props.organisationId, props.token);

    const handleCheckChange = (value) => {
        multiVoidList.includes(value)
         ? setMultiVoidList(prevState => prevState.filter(item => item !== value))
         : setMultiVoidList(prevState => [...prevState, value]);
    }

    const handleMultiVoidConfirm = () => {
        setShowVoidModal(false);
        Api.voidMultipleTickets(
            () => {
                addToast("Tickets Voided Successfully", {
                    appearance: 'success',
                    autoDismiss: true,
                });
                getBreaches();
                setMultiVoidList([]);
                setNote("");
            },
            () => {
                addToast("Error voiding tickets", {
                    appearance: 'error',
                    autoDismiss: true,
                });
            },
            multiVoidList, note, props.organisationId, props.token)
    }

    return (
        <>{breaches === null && <Loader />}
            {breaches !== null && <>
                <div className="container-fluid">
                    {/* <ChartsPlaceholder /> */}
                    <div className="row">
                        <div className="col-12">
                            <div className="dashboard-status-container">
                                {
                                   orderedCameras &&
                                   <DeviceStatus
                                       orderedDevices={orderedCameras}
                                       deviceType={Enum.DeviceType[0]}
                                       viewAll={false}
                                       viewAllUrl={allCamerasUrl}
                                       organisationId={props.organisationId}
                                       handleRefreshClick={getCameras}
                                       isLoading={camerasLoading}
                                   />
                                }
                                {
                                    kiosks &&
                                    <DeviceStatus
                                        orderedDevices={kiosks}
                                        deviceType={Enum.DeviceType[1]}
                                        viewAll={false}
                                        viewAllUrl={allKiosksUrl}
                                        organisationId={props.organisationId}
                                        handleRefreshClick={getKiosks}
                                        isLoading={kiosksLoading}
                                    />
                                }
                            </div>
                            <div className="card mb-3 col-12">
                                <Modal show={showVoidModal} onHide={() => { setShowVoidModal(false); setNote("") }}>
                                    <Modal.Header>
                                        <Modal.Title>
                                            Add a note to void multiple tickets.
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="row">
                                            <div className="col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-2">Note:</p>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group mb-0">
                                                    <textarea className="form-control mb-0" rows={4} value={note} onChange={(e) => setNote(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button
                                            className="btn btn-sm btn-danger mt-1"
                                            onClick={() => { setShowVoidModal(false); setNote("") }}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="btn btn-sm btn-success mt-1"
                                            disabled={StringHelpers.isNullOrEmpty(note)}
                                            onClick={handleMultiVoidConfirm}
                                        >
                                            Confirm
                                        </button>
                                    </Modal.Footer>
                                </Modal>
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-1 d-flex align-items-center pr-0">
                                            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Breaches</h5>
                                        </div>
                                        <div className="col-11 text-right pl-0 filter-buttons-wrapper align-items-center row">
                                            <div className="input-group col-4">
                                                {search === null && <>
                                                    <input className="form-control" value={null} type="text" placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
                                                </>}
                                                {search !== null && <>
                                                    <input className="form-control" value={search} type="text" autoFocus={true} placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
                                                </>}
                                                <div className="input-group-append">
                                                    <button className="btn btn-falcon-default btn-sm py-0" onClick={() => clearFilters(null)}><i className="fas fa-times"></i> Clear</button>
                                                </div>
                                            </div>
                                            <div className="row col-auto">
                                                <button className="btn btn-falcon-default btn-sm nowrap col-auto mx-2" data-toggle="modal" data-target="#dateModal">Filter by Date</button>
                                                <div className="filter-buttons col-auto px-2" id="select-site">
                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm" id="select-status" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{site && site !== null && <>{site.name.substring(0, 8)}</>}{!site && <>Filter by Site</>}</button>
                                                    <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-site" >
                                                        <div className="bg-white py-3 rounded-soft">
                                                            <button className="dropdown-item text-base px-3 py-2 edit-dropdown" onClick={() => setSite(null)}>All</button>
                                                            {sites && sites !== null && sites.map((currentSite) => {
                                                                return (
                                                                    <button key={currentSite.id} className="dropdown-item text-base px-3 py-2" onClick={() => setSite(currentSite)}>{currentSite.name}</button>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="filter-buttons col-auto px-2" id="select-type">
                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm" id="select-type" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{type !== null && <>Type: {Enums.ContraventionTypes[type]}</>}{type === null && <>Filter by Type</>} </button>
                                                    <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-type" >
                                                        <div className="bg-white py-3 rounded-soft">
                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setType(null)}>All</button>
                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setType(0)}>ANPR</button>
                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setType(1)}>MNPR</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="filter-buttons col-auto px-2" id="select-status">
                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm" id="select-status" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{status !== null && <>Status: {Enums.BreachStatus[status]}</>}{status === null && <>Filter by Status</>}</button>
                                                    <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-status" >
                                                        <div className="bg-white py-3 rounded-soft">
                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(null)}>All</button>
                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(0)}>New</button>
                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(503)}>Void</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button
                                                disabled={!multiVoidList || multiVoidList.length === 0}
                                                className="btn btn-danger btn-sm col-auto ml-2"
                                                onClick={() => setShowVoidModal(true)}>
                                                <i className="fas fa-times"></i> Void Tickets
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container p-0">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer">
                                        <thead className="bg-200 text-900">
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th>VRM</th>
                                                <th>Site</th>
                                                <th>Contravention</th>
                                                <th className="text-center">Breach Date</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading === true && <><tr>
                                                <td colSpan={5}>
                                                    <Loader />
                                                </td></tr></>}
                                            {loading === false && <>
                                                {breaches && breaches !== null && breaches.items !== null && breaches.items.map((breach, i) => {
                                                    var url = '/organisation/' + props.organisationId + '/breach/' + breach.id;
                                                    return (
                                                        <tr key={breach.id}>
                                                            <td>
                                                                <input
                                                                    onChange={e => handleCheckChange(e.target.value)}
                                                                    value={breach.id}
                                                                    type="checkbox"
                                                                    id={breach.id}
                                                                    name={breach.vrm}
                                                                />
                                                            </td>
                                                            <td><Link to={url} className="number-plate-link"><h6 className="mb-0 small-number-plate">
                                                                {breach.vrm}</h6></Link></td>
                                                            <td>{breach.siteName}</td>
                                                            <td>{breach.contraventionName}</td>
                                                            <td className="text-center">{breach.timestamp !== null && moment(breach.timestamp).format("DD/MM/yyyy")}</td>
                                                            <td>{Enums.BreachStatus[breach.breachStatus]}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </>}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="d-flex justify-content-center row ">
                                        <div className="col-auto ">
                                            <Pagination totalResults={breaches.totalCount} currentPage={pageNumber} changePageNumber={changePageNumber} />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade" id="dateModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="deleteModalLabel">Choose the start and end date to filter by</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Period Start</p>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group mb-0">
                                                            {!filterStartTemp &&
                                                                <>
                                                                    <input className="form-control mb-1" type="datetime-local" value={null} onChange={(e) => setFilterStartTemp(e.target.value)} />
                                                                </>}
                                                            {filterStartTemp &&
                                                                <input className="form-control mb-1" type="datetime-local" value={filterStartTemp} onChange={(e) => setFilterStartTemp(e.target.value)} />}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Period End</p>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group mb-0">
                                                            {!filterEndTemp &&
                                                                <>
                                                                    <input className="form-control mb-1" type="datetime-local" value={null} onChange={(e) => setFilterEndTemp(e.target.value)} />
                                                                </>}
                                                            {filterEndTemp &&
                                                                <input className="form-control mb-1" type="datetime-local" value={filterEndTemp} onChange={(e) => setFilterEndTemp(e.target.value)} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn btn-success btn-sm ml-2" data-dismiss="modal" onClick={() => setFilterDates()}>
                                                    Filter</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}

export default Dashboard;