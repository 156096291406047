import { useState, useEffect } from "react"
import { DatePicker } from "../shared/datePicker"
import { DownloadButton } from "./downloadButton";
import Enums from '../../utilities/enum';
import api from '../api/api';
import Loader from "../shared/loader";
import { ReportsWrapper } from "./reportsWrapper";
import { saveAs } from 'file-saver';
import StringHelpers from '../../utilities/stringHelpers';
import { fetchPreviousFullMonth } from "../../utilities/dateUtilities";
import arrayUtilities from "../../utilities/arrayUtilities";

export default function PcnsPaid(props) {
    const [pcnsPaid, setPcnsPaid] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const errorHandling = () => {
        setError(true);
        setTimeout(() => {setError(false)}, 1000)
    }
    const [fromDate, setFromDate] = useState(fetchPreviousFullMonth().firstDate);
    const [toDate, setToDate] = useState(fetchPreviousFullMonth().lastDate);

    const fetchPcnsPaidReports = () => {
        setIsLoading(true);
        api.getPcnsPaidReports((data) => {
            setPcnsPaid(data);
            setIsLoading(false);
        }, () => {
            setPcnsPaid(null);
            setIsLoading(false);
        }, fromDate, toDate, 0, props.organisationId, props.token)
    }

    const handleDownload = (reportFormat) => {
        api.getPcnsPaidReports(
            (blob, filename) => { saveAs(blob, filename) },
            errorHandling,
            fromDate,
            toDate,
            reportFormat,
            props.organisationId,
            props.token
        );
    }

    useEffect(() => {
        fetchPcnsPaidReports();
    }, [fromDate, toDate]);


    return (
        <ReportsWrapper title="PCNs Paid">
            <div className="reports-filters-container">
                <DatePicker date={fromDate} title="From" handleChange={date => setFromDate(date)} />
                <DatePicker date={toDate} title="To" handleChange={date => setToDate(date)} />
                <DownloadButton downloadError={error} reportFormat={Enums.ReportFormat[1]} handleClick={() => handleDownload(1)} />
            </div>
            {
                isLoading
                    ? <Loader />
                    : (
                        <table className="table table-striped table-sm mb-0 table-dashboard fs--1 data-table border-bottom border-200 no-footer mt-5">
                            <thead className="bg-200 text-900">
                                <tr>
                                    <th scope="col">Site Name</th>
                                    <th scope="col" className="text-right">Amount</th>
                                    <th scope="col" className="text-right">Count</th>
                                    <th scope="col" className="text-right">Subtotal</th>
                                </tr>
                            </thead>
                            <tbody className="table-striped">
                                {
                                    !arrayUtilities.isNullOrEmpty(pcnsPaid) && pcnsPaid.map(item => (
                                        <tr key={item.name}>
                                            <td>{item.name}</td>
                                            <td className="text-right">{StringHelpers.formatDecimal(item.amount, 2, "£")}</td>
                                            <td className="text-right">{StringHelpers.formatDecimal(item.count, 0)}</td>
                                            <td className="text-right">{StringHelpers.formatDecimal(item.count * item.amount, 2, "£")}</td>
                                        </tr>
                                    ))
                                }
                                <tr className="font-weight-bold">
                                    <td>Total</td>
                                    <td className="text-right col-span-2" colSpan={3}>
                                      {
                                        StringHelpers
                                          .formatDecimal(pcnsPaid.reduce((acc, curr) => acc += (curr.amount * curr.count), 0), 2, "£")
                                      }
                                      </td>
                                </tr>
                            </tbody>
                        </table>
                    )
            }
        </ReportsWrapper>
    )
}