import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications'

import './App.css';

import Header from './components/shared/header';
import Footer from './components/shared/footer';
import Loader from './components/shared/loader';
import WardenHeader from './components/warden-portal/warden-header';

import Api from './components/api/api';
import authentication from './components/authentication/b2c';

import Dashboard from './components/dashboard/dashboard';
import NonUserDashboard from './components/dashboard/non-user-dashboard';
import UserManagement from './components/user-management/user-management';
import InviteUser from './components/user-management/invite-user';
import AcceptInvite from './components/user-management/accept-invite';
import Breaches from './components/breaches/breachesDashboard';
import AllocatedBreaches from './components/breaches/allocatedBreaches';
import Breach from './components/breaches/breach';
import Organisations from './components/organisation/organisations';
import Organisation from './components/organisation/organisation';
import OrganisationSettings from './components/organisation/organisationSettings';
import CameraData from './components/raw-data/camera-data';
import KadoeData from './components/raw-data/kadoe-data';
import AllowList from './components/allow-list/allowList';
import Contraventions from './components/contraventions/contraventions';
import Contravention from './components/contraventions/contravention';
import AllowedVrm from './components/allow-list/allowedVrm';
import Client from './components/client/clients';
import Tag from './components/tags/tag'
import Tags from './components/tags/tags'
import CancellationReasons from './components/cancellations/cancellation-reasons'
import CancellationReason from './components/cancellations/cancellation-reason'
import ClientDetails from './components/client/client';
import Site from './components/sites/site';
import Camera from './components/sites/camera';
import PayDisplay from './components/sites/payDisplayMachines';
import SiteContravention from './components/sites/siteContravention';
import Enforcement from './components/enforcement/enforcementDashboard';
import ProfileManagement from './components/user-management/profile-mangement';
import ReferenceNumberRanges from './components/reference-number-ranges/reference-number-ranges';
import ReferenceNumberRange from './components/reference-number-ranges/reference-number-range';
import ContactDetails from './components/contact-details/contact-details';
import Appeal from './components/breaches/appeal';
import TransferLiability from './components/breaches/transferLiability';
import WardenDashboard from './components/warden-portal/warden-dashboard';
import WardenSite from './components/warden-portal/warden-site';
import ClientPortalHeader from './components/client-portal/client-portal-header';
import ClientLanding from './components/client-portal/client-landing';
import LetterTemplates from './components/documents/letterTemplates';
import DocumentTemplate from './components/documents/document-template';
import StringHelpers from './utilities/stringHelpers';
import Sites from './components/sites/sites';
import ExportBreaches from './components/reports/export-breaches';
import PcnsIssued from './components/reports/pcns-issued';
import PcnsPaid from './components/reports/pcns-paid';
import AllOrganisationCameras from './components/organisation/devices/cameras';
import AllOrganisationKiosks from './components/organisation/devices/kiosks';


function App() {
    //require('dotenv').config({ path: `.env.${process.env.NODE_ENV}` });
    const [user, setUser] = useState(null);
    const [organisationId, setOrganisationId] = useState(null);
    const [organisationName, setOrganisationName] = useState(null);
    const [organisations, setOrganisations] = useState(null);
    const [multiOrg, setMulitOrg] = useState(false);

    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userId, setUserId] = useState("");
    const [isNewUser, setIsNewUser] = useState(false);
    const [userRole, setUserRole] = useState(null);

    const token = authentication.getAccessToken();

    useEffect(() => {
        Api.getUser(
            (data) => {
                setUser(data);
                if (data.organisations.length === 0) {
                    setIsNewUser(true);
                } else {
                    setIsNewUser(false);
                }
            },
            () => {
                setUser(null);
                setIsNewUser(true);
            },
            token.accessToken
        );
    }, []);

    const getOrganisations = function () {
        Api.getOrganisations(
            (data) => setOrganisations(data),
            (error) => console.log(error),
            token.accessToken
        )
    }

    useEffect(() => {
        if (organisations && organisations !== null && organisations.length > 0) {
            
            var urlOrgId = null;
            if (window.location.pathname.indexOf('organisation') !== -1) {
                urlOrgId = window.location.pathname.substring(window.location.pathname.indexOf('organisation') + 13, window.location.pathname.indexOf('organisation') + 49);
            }

            if (organisations.length === 1) {
                setOrganisation(organisations[0]);
            } 
            else if (organisations.length > 1 && StringHelpers.isNullOrEmpty(urlOrgId)) {
                setMulitOrg(true)
            } else {
                organisations.forEach(org => {
                    if (org.id === urlOrgId) {
                        setOrganisation(org, 0);
                    }
                });
            }
        }
    }, [organisations])

    useEffect(() => {
        var urlOrgId = "";
        if (window.location.pathname.indexOf('organisation') !== -1) {
            urlOrgId = window.location.pathname.substring(window.location.pathname.indexOf('organisation') + 13, window.location.pathname.indexOf('organisation') + 49);
        }
        if (user !== null && user.organisations.length !== 0) {
            if (user.isGlobalAdmin) {
                getOrganisations()
            } else {
                if (user.organisations.length === 1) {
                    setOrganisation(user.organisations[0].organisation, user.organisations[0].roleId);
                } else if (user.organisations.length !== 1 && urlOrgId === "") {
                    setMulitOrg(true)
                } else {
                    user.organisations.forEach(org => {
                        if (org.organisation.id === urlOrgId) {
                            setOrganisation(org.organisation, org.roleId);
                        }
                    });
                }
            }
        } else {
            if (token.idToken !== null) {
                var newUser = token.idToken.claims;
                setUserEmail(newUser.emails[0]);
                setUserName(newUser.name);
                setUserId(newUser.sub);
            }
        }
    }, [user]);

    const setOrganisation = function (organistation, roleId) {
        if (organistation !== null && organistation != undefined) {
            setOrganisationId(organistation.id);
            setOrganisationName(organistation.name);
            setUserRole(roleId);
        } else {
            setOrganisationId(null);
            setOrganisationName(null);
            setUserRole(null);
        }
    };

    return (
        <>
            {!isNewUser && !user &&
                <Loader />
            }
            {isNewUser &&
                <Router>
                    <Switch>
                        <Route exact path="/" render={() => <NonUserDashboard />} />
                        <Route exact path="/user-management/accept-invite/:inviteId" render={(props) => <AcceptInvite {...props} user={user} name={userName} email={userEmail} userId={userId} token={token.accessToken} organisationId={organisationId} />} />
                    </Switch>
                </Router>
            } {user && user !== null &&
                <div className="wrapper">
                    {organisationId !== null && organisationId !== undefined && userRole !== 6 && userRole !== 5 && <>
                        <Router>
                            <Header user={user} organisationName={organisationName} organisationId={organisationId} />
                            <ToastProvider>
                                <Switch>
                                    <Route exact path="/" render={(props) => <Dashboard {...props} user={user} token={token.accessToken} organisationId={organisationId} />} />
                                    <Route exact path="/profile-management" render={(props) => <ProfileManagement {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/user-management/invite-user" render={(props) => <InviteUser {...props} organisationName={organisationName} user={user} token={token.accessToken} organisationId={organisationId} />} />
                                    <Route exact path="/user-management/accept-invite/:inviteId" render={(props) => <AcceptInvite {...props} user={user} token={token.accessToken} organisationId={organisationId} />} />
                                    <Route exact path="/organisation/:organisationId/organisations" render={(props) => <Organisations {...props} user={user} token={token.accessToken} />} organisationId={organisationId} />
                                    <Route exact path="/organisations" render={(props) => <Organisations {...props} user={user} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/reference-number-ranges" render={(props) => <ReferenceNumberRanges {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/reference-number-range/:numberRangeId?" render={(props) => <ReferenceNumberRange {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/organisation-settings" render={(props) => <OrganisationSettings {...props} user={user} token={token.accessToken} organisationId={organisationId} />} />
                                    <Route exact path="/organisation/:organisationId?" render={(props) => <Organisation {...props} user={user} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/cancellation-reasons" render={(props) => <CancellationReasons {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/cancellation-reason/:reasonId?" render={(props) => <CancellationReason {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/tag-settings" render={(props) => <Tags {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/tag-settings/tag/:tagId?" render={(props) => <Tag {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/allow-list" render={(props) => <AllowList {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/allowed-vrm/:allowedVrmId?" render={(props) => <AllowedVrm {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/contravention-settings" render={(props) => <Contraventions {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/contravention/:contraventionId?" render={(props) => <Contravention {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/raw-data/camera-data" render={(props) => <CameraData {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/raw-data/kadoe" render={(props) => <KadoeData {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/sites" render={(props) => <Sites {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/clients" render={(props) => <Client {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/client/:clientId?" render={(props) => <ClientDetails {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/client/:clientId/site/:siteId?" render={(props) => <Site {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/site/:siteId/camera/:cameraId?" render={(props) => <Camera {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/site/:siteId/pay-display-machine/:payDisplayId?" render={(props) => <PayDisplay {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/site/:siteId/contravention/:contraventionId?" render={(props) => <SiteContravention {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/breach/:breachId/contact-details/:detailsId?" render={(props) => <ContactDetails {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/breach/:breachId/appeal/:appealId?" render={(props) => <Appeal {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/breach/:breachId/transfer-of-liability" render={(props) => <TransferLiability {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/letter-templates" render={(props) => <LetterTemplates {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/document-template/:documentId?" render={(props) => <DocumentTemplate {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />

                                    <Route exact path="/organisation/:organisationId/devices/cameras" render={(props) => <AllOrganisationCameras {...props} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/devices/kiosks" render={(props) => <AllOrganisationKiosks {...props} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/reports/export-breaches" render={(props) => <ExportBreaches {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/reports/pcns-issued" render={(props) => <PcnsIssued {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/reports/pcns-paid" render={(props) => <PcnsPaid {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />


                                    <Route exact path="/organisation/:organisationId/breaches" render={(props) => <Breaches {...props} user={user} token={token.accessToken} organisationId={organisationId} />} />
                                    <Route exact path="/organisation/:organisationId/breach/:breachId" render={(props) => <Breach {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/allocated-breaches" render={(props) => <AllocatedBreaches {...props} user={user} token={token.accessToken} organisationId={organisationId} />} />
                                    <Route exact path="/organisation/:organisationId/enforcement" render={(props) => <Enforcement {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                                    <Route exact path="/organisation/:organisationId/user-management" render={(props) => <UserManagement {...props} user={user} token={token.accessToken} organisationId={organisationId} />} />
                                </Switch>
                            </ToastProvider>

                        </Router>
                    </>}
                    {organisationId !== null && userRole === 6 && <>
                        <Router>
                            <WardenHeader user={user} organisationName={organisationName} organisationId={organisationId} />
                            <Switch>
                                <ToastProvider>
                                    <Route exact path="/" render={(props) => <WardenDashboard {...props} user={user} token={token.accessToken} organisationId={organisationId} />} />
                                    <Route exact path="/site/:siteId" render={(props) => <WardenSite {...props} user={user} token={token.accessToken} organisationId={organisationId} />} />
                                </ToastProvider>
                            </Switch>
                        </Router>
                    </>}
                    {organisationId !== null && userRole === 5 && <>
                        <Router>
                            <ClientPortalHeader user={user} organisationName={organisationName} organisationId={organisationId} />
                            <Switch>
                                <Route exact path="/" render={(props) => <ClientLanding {...props} user={user} organisationName={organisationName} token={token.accessToken} organisationId={organisationId} />} />
                                <Route exact path="/allowed-vrm/:allowedVrmId?" render={(props) => <AllowedVrm {...props} user={user} organisationId={organisationId} token={token.accessToken} />} />
                            </Switch>
                        </Router>
                    </>}
                    {multiOrg && organisationId === null &&   <div class="container" data-layout="container">
                        <div className="row flex-center min-vh-75 py-6">
                            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                                <a className="d-flex flex-center mb-5" href="/">
                                    <img src="/assets/img/apt-logos/logo.svg" alt="" width="150" />
                                </a>
                                <div className="card">
                                    <div className="card-header bg-light">
                                        <h5 className="mb-0">Select an organisation</h5>
                                    </div>
                                    <div className="card-body p-2 px-sm-2 pt-sm-5">                                    
                                        {organisations === null && <>  
                                                {user.organisations && user.organisations !== null && user.organisations.map((org) => 
                                                    <a className="btn btn-link btn-block d-flex justify-content-between align-items-center" href="#" key={org.organisation.id} onClick={() => setOrganisation(org.organisation, org.roleId)}>
                                                    <span>{org.organisation.name}</span>
                                                    <span className="fas fa-chevron-right fs--2 ml-1"></span>
                                                </a>)}
                                            </>
                                        }
                                        {organisations !== null &&
                                            <>
                                                {organisations.map((org) => 
                                                    <a className="btn btn-link btn-block d-flex justify-content-between align-items-center" href="#" key={org.id} onClick={() => setOrganisation(org)}>
                                                        <span>{org.name}</span>
                                                        <span className="fas fa-chevron-right fs--2 ml-1"></span>
                                                    </a>
                                                )}
                                            </>
                                        }
                                    
                                        <div className="w-100 position-relative mt-5">
                                            <hr className="text-300 mb-2" />
                                        </div>
                                        <div className="form-group mb-0">
                                            <div className="row no-gutters d-flex justify-content-end">
                                                <button className="btn btn-default btn-sm" onClick={() => authentication.signOut()}>Sign Out</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    
                    <Footer />
                </div>}
        </>
    );
}

export default App;
