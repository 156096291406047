import { Link } from 'react-router-dom';
import Enums from '../../utilities/enum';

export const DevicesStatusTable = (props) => {

    return (
        <tbody>
            {
                props.filteredDevices?.map(item => {
                    const url = '/organisation/' + props.organisationId + '/site/' + item?.filteredDevice?.siteId + '/camera/' + item?.filteredDevice?.id
                    const deviceName = <h6 className="mb-0">{item?.filteredDevice?.name}</h6>

                    return (
                        <tr key={item?.filteredDevice?.id}>
                            <td>
                                {
                                    props.deviceType === Enums.DeviceType[0]
                                        ? (<Link to={url}>{deviceName}</Link>)
                                        : (deviceName)
                                }
                            </td>
                            <td>{item?.filteredDevice?.siteName}</td>
                            <td className="text-right heartbeat pr-3">
                                {item?.pillButton}
                            </td>
                        </tr>
                    )
                })
            }
        </tbody>
    )

}